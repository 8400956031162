import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import LoadingIndicator from "../../../loading-indicator";

class ParagraphNewsletteranmeldung extends Component {
  state = {
    error: false,
    errorMsg: "",
    loading: false,
    success: false,
    successMsg: "",
  };

  onSubmit = (values, actions) => {
    this.setState({ loading: true });

    setTimeout(() => {
      this.setState({
        loading: false,
        success: true,
        successMsg: "Sie wurden in den Newsletter eingetragen.",
      });
      actions.setSubmitting(false);
    }, 750);
  };

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-newsletter-register": true,
    });

    return (
      <section className={sectionClassNames}>
        {this.state.loading && <LoadingIndicator />}

        <div className="container">
          <div className="row">
            <div className="col-16">
              {this.state.error && !this.state.errorMsg && (
                <div
                  className="alert alert-danger"
                  role="region"
                  aria-live="polite"
                >
                  Es ist ein Fehler aufgetreten.
                </div>
              )}

              {this.state.error && this.state.errorMsg && (
                <div
                  className="alert alert-danger"
                  role="region"
                  aria-live="polite"
                >
                  {this.state.errorMsg}
                </div>
              )}

              {this.state.success && this.state.successMsg && (
                <div
                  className="alert alert-success"
                  role="region"
                  aria-live="polite"
                >
                  {this.state.successMsg}
                </div>
              )}
            </div>
            <div className="col-16 col-md-8">
              <h3>{this.props.content.fieldHeading}</h3>
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: this.props.content.fieldText.processed,
                }}
              />
            </div>
            <div className="col-16 col-md-8">
              <Formik
                onSubmit={(values, actions) => {
                  this.onSubmit(values, actions);
                }}
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={{
                  email: "",
                  consent: false,
                }}
                validationSchema={Yup.lazy(() =>
                  Yup.object().shape({
                    email: Yup.string().email().required("E-Mail is required"),
                    consent: Yup.bool().oneOf(
                      [true],
                      "Sie müssen den Bedingungen zustimmen."
                    ),
                  })
                )}
              >
                {({ errors, touched }) => (
                  <Form noValidate>
                    <div className="form-row">
                      <div className="col-16 form-group">
                        <label htmlFor="email">Ihre E-Mail-Adresse</label>
                        <Field
                          aria-label="Ihre E-Mail-Adresse"
                          type="email"
                          name="email"
                          id="email"
                          className="form-control"
                          placeholder="E-Mail"
                        />
                        {errors.email && touched.email ? (
                          <div
                            className="form-error text-danger"
                            role="region"
                            aria-live="polite"
                          >
                            {errors.email}
                          </div>
                        ) : null}
                      </div>

                      <div className="col-16 form-check">
                        <Field
                          type="checkbox"
                          name="consent"
                          id="consent"
                          aria-labelledby="form-check-label"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="consent">
                          Ja, ich bin einverstanden. Dieses Einverständnis kann
                          ich jederzeit widerrufen.
                          {errors.consent && touched.consent ? (
                            <span
                              className="form-error text-danger"
                              role="region"
                              aria-live="polite"
                            >
                              {errors.consent}
                            </span>
                          ) : null}
                        </label>
                      </div>
                    </div>

                    <div className="form-row actions">
                      <div className="col-16 d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary">
                          Newsletter bestellen
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphNewsletteranmeldung.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    fieldText: PropTypes.shape({
      processed: PropTypes.string,
    }),
  }),
};

export default ParagraphNewsletteranmeldung;
