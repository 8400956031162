import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { graphql } from "@apollo/react-hoc";
import teaserNodeQueryStellenausschreibung from "../../../teaser-base/queries/teaser-node-query-stellenausschreibung.graphql";
import LoadingIndicator from "../../../loading-indicator";
import { Link } from "react-router-dom";
import ErrorBoundary from "../../../../error-boundary";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from "react-accessible-accordion";
import {self} from "../../../../config";

class ParagraphStellenangebote extends Component {

  componentDidMount() {
    if (window && window.location.hash) {
      let scrollToObject = document.getElementById(window.location.hash.replace("#", ""));

      if (scrollToObject) {
        window.scroll({
          top: scrollToObject.getBoundingClientRect().top + document.documentElement.scrollTop - 200,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  }

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-stellenangebote": true,
    });

    if (this.props.data.nodeQuery && this.props.data.nodeQuery.entities) {
        return (
          <section className={sectionClassNames}>
            <div className="container">
              <div className="row">
                <div className="col-16 col-lg-14 offset-lg-2">
                  {this.props.data.nodeQuery.entities && this.props.data.nodeQuery.entities.map(function(item, index) {
                    return (
                      <article className="node-teaser-job" key={index}>
                        <Accordion allowZeroExpanded={true}>
                          <ErrorBoundary key={index}>
                            <AccordionItem>
                              <AccordionItemHeading>
                                <AccordionItemButton>
                                  <h2 className="title">{item.title}</h2>
                                  <img src={`${self}/arrow-right.svg`} alt={"mehr erfahren"}/>
                                </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                {item.body &&
                                  <div
                                    className="text"
                                    dangerouslySetInnerHTML={{
                                      __html: item.body.processed
                                    }}
                                  />
                                }
                              </AccordionItemPanel>
                            </AccordionItem>
                          </ErrorBoundary>
                        </Accordion>
                      </article>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        );
      }

    return <LoadingIndicator />;
    }
}

ParagraphStellenangebote.propTypes = {};

export default graphql(teaserNodeQueryStellenausschreibung, {
  options: (props) => ({
    variables: {
      limit: 50,
    },
  }),
})(ParagraphStellenangebote);
