import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ChevronUp24, ChevronDown24 } from "@carbon/icons-react";

import Image from "../../../image/image";
import FieldTime from "../../../date-time/field-time";
import ErrorBoundary from "../../../../error-boundary";
import { connect } from "react-redux";
import { self } from "../../../../config";

const mapStateToProps = (reduxStore) => ({
  microsite: reduxStore.appStore.microsite,
});

class ParagraphDownloadSchublade extends Component {
  state = { open: false };

  componentDidMount() {
    // Check the value of 'microsite' in the Redux store
    if (this.props.microsite) {
      // If 'microsite' is true, set 'open' to true
      this.setState({ open: true });
    }
  }

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-download-schublade": true,
    });
    if (this.props.microsite) {
      return (
        <section className={sectionClassNames}>
          <div className="container">
            <div className="row">
              <div
                className={classNames({
                  "col-wrapper col-16": true,
                  "col-16 col-md-15 offset-md-1": !this.props.microsite,
                })}
              >
                <div className="row">
                  <div className="col-16">
                    <h3>{this.props.content.fieldTitel}</h3>
                  </div>
                </div>
                <div className="background-wrapper">
                  <div className="item-wrap">
                    {this.props.content.fieldDownloads.map((item, index) => (
                      <div className="row downloads" key={index}>
                        <a
                          href={item.entity.fieldMediaFile.entity.url}
                          className="download"
                          download={true}
                        >
                          <div className="col-14 col-md-15 text">
                            <div className="download-title">
                              {item.entity.entityLabel.replace(/\.[^/.]+$/, "")}
                            </div>
                          </div>
                          <div className="col-2 col-md-1 icon-wrap">
                            <img
                              className="download-icon alternative"
                              src={`${self}/download-arrow-alt-color.svg`}
                              alt="download-icon"
                            />
                            <img
                              className="download-icon regular"
                              src={`${self}/download-arrow.svg`}
                              alt="download-icon"
                            />
                          </div>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    } else {
      return (
        <section className={sectionClassNames}>
          <div className="container">
            <div className="row">
              <div className="col-16 col-md-15 offset-md-1">
                <div className="row">
                  <div className="col-16 col-md-12">
                    <header>
                      <ErrorBoundary>
                        <FieldTime
                          date={this.props.content.fieldDatum.value}
                          format={"DD.MM.YYY"}
                        />
                      </ErrorBoundary>
                      <span>{this.props.content.fieldRubrikOderKategorie}</span>
                    </header>
                    <h3>{this.props.content.fieldTitel}</h3>
                    {this.props.content.fieldBeschreibung && (
                      <div
                        className="text"
                        dangerouslySetInnerHTML={{
                          __html:
                            this.props.content.fieldBeschreibung.processed,
                        }}
                      />
                    )}
                  </div>
                  <div className="d-none col-16 col-md-4 d-md-flex justify-content-end align-items-baseline">
                    <div
                      className="btn btn-secondary btn-icon"
                      onClick={() => this.setState({ open: !this.state.open })}
                    >
                      {this.state.open ? (
                        <>
                          Einklappen <ChevronUp24 />
                        </>
                      ) : (
                        <>
                          Ausklappen <ChevronDown24 />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={classNames({
                    "row downloads": true,
                    "d-none": !this.state.open,
                    "d-flex": this.state.open,
                  })}
                >
                  {this.props.content.fieldDownloads.map((item, index) => (
                    <div className="col-8 col-md-3" key={index}>
                      <a
                        href={item.entity.fieldMediaFile.entity.url}
                        className="download"
                        download={true}
                      >
                        {item.entity.fieldVorschaubild !== null ? (
                          <ErrorBoundary>
                            <Image
                              data={
                                item.entity.fieldVorschaubild.entity
                                  .fieldMediaImage
                              }
                            />
                          </ErrorBoundary>
                        ) : (
                          <div className="text-image">
                            {item.entity.fieldMediaFile.entity.url
                              .split(".")
                              .pop()}
                          </div>
                        )}
                        <div className="download-title">
                          {item.entity.entityLabel}
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
                <div className="row">
                  <div className="d-flex col-16 col-md-4 d-md-none justify-content-end align-items-baseline">
                    <div
                      className="btn btn-secondary"
                      onClick={() => this.setState({ open: !this.state.open })}
                    >
                      {this.state.open ? (
                        <>
                          Einklappen <ChevronUp24 />
                        </>
                      ) : (
                        <>
                          Ausklappen <ChevronDown24 />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
}

ParagraphDownloadSchublade.propTypes = {
  content: PropTypes.shape({
    fieldDatum: PropTypes.shape({
      value: PropTypes.string,
    }),
    fieldRubrikOderKategorie: PropTypes.string,
    fieldTitel: PropTypes.string,
    fieldBeschreibung: PropTypes.shape({
      processed: PropTypes.string,
    }),
    fieldDownloads: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          entityLabel: PropTypes.string,
          fieldVorschaubild: PropTypes.shape({
            entity: PropTypes.shape({
              fieldMediaImage: PropTypes.shape({
                alt: PropTypes.string,
                title: PropTypes.string,
                style: PropTypes.shape({
                  url: PropTypes.string,
                }),
              }),
            }),
          }),
          fieldMediaFile: PropTypes.shape({
            entity: PropTypes.shape({
              url: PropTypes.string,
            }),
          }),
        }),
      })
    ),
  }),
};

export default connect(mapStateToProps)(ParagraphDownloadSchublade);
