import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ErrorBoundary from "../error-boundary";
import LoadingIndicator from "../general-components/loading-indicator";
import ContentBase from "../general-components/content-base/content-base";
import projektPageQuery from "./projekt.graphql";
import PagerFullPage from "../general-components/pager-full-page";
import Image from "../general-components/image/image";
import Link from "../general-components/link/link";
/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{pagerConfig: ([]|*[])}} - Relevant Data for App Component from
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  pagerFullPageContextId: reduxStore.appStore.pagerFullPageContextId,
  pagerConfig: reduxStore.appStore.pagerFullPages,
});

class Projekt extends Component {

  render() {

    if (this.props.data.route) {
      const published = this.props.data.route.entity.status
        ? "node-published"
        : "node-unpublished";

      return (
        <article
          className={`node-${this.props.data.route.entity.entityBundle} node-${this.props.data.route.entity.entityId} node-full-page ${published} body-scroll-lock-ignore`}
          style={{ "--teaser-color": this.props.data.route.entity.fieldFarbe ? this.props.data.route.entity.fieldFarbe.color : "#000" }}
        >
          {/*<Helmet>
            <title>{`GEDISA | ${this.props.data.route.entity.entityLabel}`}</title>
          </Helmet>*/}
          <div className={"product-header"}>
            <ErrorBoundary>
              {this.props.data.route.entity.fieldTeaserbanner &&
                <Image
                  className="background-image"
                  data={this.props.data.route.entity.fieldTeaserbanner.entity.fieldMediaImage}
                />
              }
              <div className="container full-size header-title">
                <div className="row">
                  <div className="col-16">
                    {this.props.data.route.entity.entityLabel &&
                      <div
                        className="site-heading"
                        dangerouslySetInnerHTML={{
                          __html: this.props.data.route.entity.entityLabel,
                        }}
                      />
                    }
                  </div>
                </div>
              </div>
            </ErrorBoundary>
          </div>

          <div className="container full-size main-content">
            <div className="row">
              <div className="col-16 col-lg-8">
                {this.props.data.route.entity.fieldZwischenueberschrift &&
                  <h3
                    className="teil-ueberschrift"
                    dangerouslySetInnerHTML={{
                      __html: this.props.data.route.entity.fieldZwischenueberschrift,
                    }}
                  />
                }
                {this.props.data.route.entity.fieldBeschreibung &&
                  <div
                    className="beschreibung text"
                    dangerouslySetInnerHTML={{
                      __html: this.props.data.route.entity.fieldBeschreibung.processed,
                    }}
                  />
                }
                {this.props.data.route.entity.fieldButton &&
                  <a href={this.props.data.route.entity.fieldButton.url.path}>
                    <button className="cta">
                      {this.props.data.route.entity.fieldButton.title}
                    </button>
                  </a>

                }
              </div>
              <div className="col-16 col-lg-8">
                {this.props.data.route.entity.fieldTeasericons &&
                  <Image
                    className="teaser-icon"
                    data={this.props.data.route.entity.fieldTeasericons.entity.fieldMediaImage}
                  />
                }
                <div className="img-wrap">
                  {this.props.data.route.entity.fieldProjektbild &&
                    <Image
                      className="projekt-bild"
                      data={this.props.data.route.entity.fieldProjektbild.entity.fieldMediaImage}
                    />
                  }
                  {this.props.data.route.entity.fieldFarbe &&
                    <div className="blur"/>
                  }
                </div>
              </div>
            </div>
          </div>

          {this.props.data.route.entity.fieldModules && (
            <ContentBase content={this.props.data.route.entity.fieldModules} />
          )}
        </article>
      );
    }
    return <LoadingIndicator fullPage={true}/>;
  }
}

Projekt.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object.isRequired,
  pagerConfig: PropTypes.array,
  pagerFullPageContextId: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
};

export default graphql(projektPageQuery, {
  options: (props) => ({
    variables: { path: props.customPath ? props.customPath : props.location.pathname },
  }),
})(connect(mapStateToProps)(withRouter(Projekt)));
