import React, { Component } from "react";
import PropTypes from "prop-types";

import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import TeaserQueryCloudContent from "./queries/teaser-query-cloud-content.graphql";
import LazyLoad from "react-lazyload";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import CloudDetailContent from "./cloud-detail-content";

import GridView from "./grid-view";
import StoryContainer from "./components/cloud/story-container";
import { withRouter } from "react-router-dom";
import { viewModeAction } from "./cloud-view-action";
import LoadingIndicator from "../../../loading-indicator";

const mapStateToProps = (reduxStore) => ({
    cloudView: reduxStore.appStore.cloudView,
    activeCloudDetail: reduxStore.appStore.activeCloudDetail,
    filterActive: reduxStore.filterActive,
    mobile: reduxStore.appStore.mobile,
  }),
  XIcon = () => (
    <svg
      className="x-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10.2 11.6"
    >
      <path d="M9.9 10L6.3 5.7l3.5-4.1c.3-.4.3-1-.1-1.3-.4-.4-1-.3-1.3.1L5.1 4.3 1.8.4C1.5 0 .9-.1.5.3.1.6 0 1.2.4 1.6l3.5 4.1L.3 10c-.3.4-.3 1 .1 1.3.2.2.4.2.6.2.3 0 .5-.1.7-.3l3.4-4 3.4 4c.2.2.5.3.7.3.2 0 .4-.1.6-.2.4-.3.5-.9.1-1.3z" />
    </svg>
  );

let lastScrollTop = 0;

class ContentCloud extends Component {
  container = React.createRef();
  searchElement = React.createRef();

  state = {
    viewMode: "grid",
    entities: [],
    showSearch: false,
    position: {
      x: 0,
      y: 0,
    },
  };

  toggleViewMode = (type) => {
    window.scrollTo(0, 0);
    this.setState({ viewMode: type });
    this.props.dispatch(viewModeAction(!this.state.viewMode));
  };

  scaleStoryElements = () => {
    setTimeout(() => {
      document
        .querySelectorAll(".cloud-teaser .teaser-wrapper")
        .forEach((item) => item.classList.add("scaled"));
    }, 1000);
  };

  scrollListener = (elements) => {
    if (elements.length > 0) {
      const st = window.pageYOffset || document.documentElement.scrollTop;

      lastScrollTop = st <= 0 ? 0 : st;

      if (st >= window.innerHeight) {
        elements[0].classList.add("slide-in-first");
      } else {
        elements[0].classList.remove("slide-in-first");
      }

      if (st >= window.innerHeight * 1.5) {
        elements[0].classList.add("slide-in-second");
      } else {
        elements[0].classList.remove("slide-in-second");
      }
    }

    if (this.state.showSearch && this.props.mobile) {
      this.setState({ showSearch: false });
    }
  };

  handleClickOutside(event) {
    if (this.searchElement) {
      if (this.searchElement.current != null) {
        if (!this.searchElement.current.contains(event.target)) {
          this.setState({ showSearch: false });
        }
      }
    }
  }

  showSearch = () => {
    this.setState({ showSearch: true });

    const InputFilterElement = document.getElementsByName("text-title");

    InputFilterElement[0].focus();
  };

  closeSearch = () => {
    this.setState({ showSearch: false });
  };

  componentDidMount() {
    const dialogElements = document.querySelectorAll(".dialogue-wrapper");

    window.addEventListener("scroll", () =>
      this.scrollListener(dialogElements)
    );
    window.addEventListener("mousedown", (event) =>
      this.handleClickOutside(event)
    );

    if (this.container.current != null) {
      /* Scale Items for Start*/
      this.scaleStoryElements();
    }
  }

  render() {
    const { error, loading, nodeQuery } = this.props.cloudContent;

    if (loading) {
      return <LoadingIndicator />;
    }

    if (error) {
      console.log(error);
    }

    let entities = null;

    if (nodeQuery && !loading && !error) {
      entities = nodeQuery.entities;
    }

    let ammountPerWrapper = 5;

    return (
      <div className={`content-wrap`}>
        <div className="header">
          <div className="wrapper">
            <div className="function" id="header-function">
              <div className={`view-chooser item`}>
                <span
                  className={`grid-icon ${
                    this.state.viewMode === "grid" ? "active" : ""
                  }`}
                  onClick={() => this.toggleViewMode("grid")}
                >
                  grid
                </span>
                <span
                  className={`list-icon ${
                    this.state.viewMode === "list" ? "active" : ""
                  }`}
                  onClick={() => this.toggleViewMode("list")}
                >
                  list
                </span>
              </div>
            </div>
          </div>
        </div>
        {this.state.viewMode === "grid" ? (
          <div
            ref={this.container}
            className={`container paragraph paragraph-cloud-view ${
              this.props.activeCloudDetail ? "detail-opened" : ""
            }`}
            id="cloud-view-draggable"
          >
            <div className="cloud-detail-scroll-container">
              {this.props.activeCloudDetail && (
                <CloudDetailContent content={this.props.activeCloudDetail} />
              )}
            </div>
            <StoryContainer
              entities={entities.slice(0, ammountPerWrapper)}
              scaleStoryElements={this.scaleStoryElements}
            />
            <>
              {[...Array(Math.ceil(entities.length / ammountPerWrapper))].map(
                (time, i) => {
                  return (
                    <LazyLoad key={i} offset={100}>
                      <StoryContainer
                        entities={entities.slice(
                          (i + 1) * ammountPerWrapper,
                          (i + 2) * ammountPerWrapper
                        )}
                        scaleStoryElements={this.scaleStoryElements}
                      />
                    </LazyLoad>
                  );
                }
              )}
            </>
            <div className="dialogue-wrapper">
              {this.props.content.fieldDialogelemente.map((item) => {
                return (
                  <div
                    key={JSON.stringify(item)}
                    className="dialog-element"
                    dangerouslySetInnerHTML={{
                      __html: item.value,
                    }}
                  />
                );
              })}
            </div>
          </div>
        ) : (
          <div className="grid-view">
            <GridView entities={entities} />
          </div>
        )}
      </div>
    );
  }
}

ContentCloud.propTypes = {
  cloudContent: PropTypes.object.isRequired,
  cloudView: PropTypes.object,
  content: PropTypes.object.isRequired,
};

export default withRouter(
  withTranslation()(
    compose(
      graphql(TeaserQueryCloudContent, {
        name: "cloudContent",
      })
    )(connect(mapStateToProps)(ContentCloud))
  )
);
