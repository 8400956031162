import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";

class GridItem extends Component {
  render() {
    const { content } = this.props,
      GRIDCLASSITEM = classNames({
        "grid-item": true,
        "col-16": true,
      }),
      item = content;

    return (
      <div className={GRIDCLASSITEM}>
        <Link
          to={{
            pathname: item.entityUrl.path,
            state: {
              content,
            },
          }}
          ref={this.contentWrapper}
        >
          <div className={`content-wrapper in-view`}>
            <div className="row">
              <div className="item-image col-16 col-md-4">
                <img
                  className="grid-img"
                  src={item.fieldBild.entity.fieldMediaImage.style.url}
                />
              </div>
              <div className="col-16 col-md-12 item-text">
                <div className="title">
                  <h3>{item.entityLabel}</h3>
                </div>
                <div className="bottom-wrapper">
                  {!!item.fieldTeasertext && (
                    <div
                      className="text"
                      dangerouslySetInnerHTML={{
                        __html: item.fieldTeasertext.value,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

GridItem.propTypes = {
  content: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default GridItem;
