import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {ArrowRight32, ArrowLeft32} from "@carbon/icons-react";

import ComponentBildSlider from "./component-bild-slider";
import ErrorBoundary from "../../../../error-boundary";
import {connect} from "react-redux";
import Image from "../../../image/image";

const mapStateToProps = (reduxStore) => ({
  microsite: reduxStore.appStore.microsite,
});

class ParagraphBilderSlider extends Component {
  state = {
    sliderSettings: {
      adaptiveHeight: true,
      arrows: true,
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      touchMove: false,
      nextArrow: <ArrowRight32 width={48} height={48} viewBox="0 0 32 24"/>,
      prevArrow: <ArrowLeft32 width={48} height={48} viewBox="0 0 32 24"/>,
    },
  };

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-bilder-slider": true, [`format-${this.props.content.fieldFormat}`]: true,
    });

    // in case, there is no image set (should be required field) @todo: This results in no image shown!
    /*if (!!this.props.content.fieldBilderWCaption?.length) {
      return;
    }*/

    let sliderOrImage = <></>;
    if (this.props.microsite === "ggb") {
      sliderOrImage = (<div className="container">
        <div className="row">
          <div className="col-16 col-md-12 offset-md-2 col-xl-8 offset-xl-4">
            <ErrorBoundary>
              <article className="media-image">
                <div className="content-wrap">
                  {this.props.content.fieldImageTitle &&
                    <h2 className="headline">{this.props.content.fieldImageTitle}</h2>
                  }
                  <Image
                    data={this.props.content.fieldBilderWCaption[0].entity.fieldMediaImage}
                  />
                  {this.props.content.fieldBildunterschrift &&
                    <p className="description">{this.props.content.fieldBildunterschrift} </p>
                  }
                </div>
              </article>
            </ErrorBoundary>
          </div>
        </div>
      </div>);
      return <section className={sectionClassNames}>{sliderOrImage}</section>;
    } else {
      if (this.props.content.fieldFormat === "full") {
        sliderOrImage = (<div className="container">
          <div className="row">
            <div className="col-16 col-lg-14 offset-lg-2">
              <ErrorBoundary>
                <ComponentBildSlider
                  wrapCaption={true}
                  bilder={this.props.content.fieldBilderWCaption}
                  sliderSettings={this.state.sliderSettings}
                  globalCaption={this.props.content.fieldBildunterschrift}
                />
              </ErrorBoundary>
            </div>
          </div>
        </div>);
      } else {
        sliderOrImage = (<div className="container">
          <div className="row">
            <div
              className={classNames({
                "col-16": true,
                "col-lg-8 offset-lg-4": this.props.content.fieldFormat === "center",
                "col-lg-8": this.props.content.fieldFormat === "left",
                "col-lg-8 offset-lg-8": this.props.content.fieldFormat === "right",
              })}
            >
              <ErrorBoundary>
                <ComponentBildSlider
                  bilder={this.props.content.fieldBilderWCaption}
                  sliderSettings={this.state.sliderSettings}
                  globalCaption={this.props.content.fieldBildunterschrift}
                />
              </ErrorBoundary>
            </div>
          </div>
        </div>);
      }

      return <section className={sectionClassNames}>{sliderOrImage}</section>;
    }
  }
}

ParagraphBilderSlider.propTypes = {
  content: PropTypes.shape({
    fieldBildunterschrift: PropTypes.string, fieldBilderWCaption: PropTypes.arrayOf(PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string, title: PropTypes.string, style: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        }),
      }),
    })), fieldFormat: PropTypes.oneOf(["center", "left", "right", "full"]),
  }),
};

export default connect(mapStateToProps)(ParagraphBilderSlider);
