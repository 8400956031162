const adminAppAction = (adminApp) => ({
    adminApp,
    type: "ADMIN_APP",
  }),
  micrositeAction = (microsite) => ({
    microsite,
    type: "MICROSITE",
  }),
  microSiteFrontPageAction = (microSiteFrontPage) => ({
    microSiteFrontPage,
    type: "MICROSITE_FRONTPAGE",
  }),
  pagerFullPagesAction = (config) => ({
    config,
    type: "PAGER_FULL_PAGE",
  }),
  pagerFullPageContextAction = (id) => ({
    id,
    type: "PAGER_FULL_PAGE_CONTEXT_ID",
  });

export { adminAppAction, pagerFullPagesAction, pagerFullPageContextAction ,micrositeAction, microSiteFrontPageAction };
