import React, { Component } from "react";
import PropTypes from "prop-types";

import ParagraphHeroText from "./paragraphs/hero-text/paragraph-hero-text";
import ContentCloud from "./paragraphs/content-cloud/content-cloud";
import ParagraphTextAndSpeech from "./paragraphs/text-and-speech/paragraph-text-and-speech";
import ParagraphClickSuggestions from "./paragraphs/click-suggestions/paragraph-click-suggestions";
import ParagraphTeaserList from "./paragraphs/teaserliste/paragraph-teaser-list";
import ParagraphTeaserImage from "./paragraphs/teaser-image/paragraph-teaser-image";
import ParagraphSectionheading from "./paragraphs/section-heading/paragraph-sectionheading";
import ParagraphText from "./paragraphs/text/paragraph-text";
import Eforkbot from "./../../eforkbot/eforkbot";
import ParagraphBilderSlider from "./paragraphs/image-image-slider/paragraph-bilder-slider";
import ParagraphNumberFacts from "./paragraphs/number-facts/paragraph-number-facts";
import ParagraphTeaserSlider from "./paragraphs/teaser-slider/paragraph-teaser-slider";
import ParagraphNewsletteranmeldung from "./paragraphs/newsletter/paragraph-newsletteranmeldung";
import ParagraphAkkordionText from "./paragraphs/akkordion-text/paragraph-akkordion-text";
import ParagraphDossier from "./paragraphs/dossier/paragraph-dossier";
import ParagraphMedia from "./paragraphs/media-video-audio/paragraph-media";
import ParagraphPageTitle from "./paragraphs/page-title/paragraph-page-title";
import ParagraphCallToAction from "./paragraphs/cta/paragraph-call-to-action";
import ParagraphExtendedTeaserOverview from "./paragraphs/extended-teaser-list/paragraph-extended-teaser-overview";
import ParagraphCalendarInformation from "./paragraphs/kalender-information/paragraph-calendar-information";
import ParagraphDownload from "./paragraphs/download/paragraph-download";
import ParagraphZitat from "./paragraphs/zitat/paragraph-zitat";
import ParagraphDownloadSchublade from "./paragraphs/download-schublade/paragraph-download-schublade";
import ParagraphTimeline from "./paragraphs/timeline/paragraph-timeline";
import ParagraphKontakt from "./paragraphs/kontakt/paragraph-kontakt";
import ParagraphStrukturierteListe from "./paragraphs/strukturierte-liste/paragraph-strukturierte-liste";
import ParagraphPersonenInformationen from "./paragraphs/personen-informationen/paragraph-personen-informationen";
import ParagraphKalenderEinzelDoppel from "./paragraphs/kalender-einzel-doppel/paragraph-kalender-einzel-doppel";
import ParagraphKalenderAbschnitt from "./paragraphs/kalender-abschnitt/paragraph-kalender-abschnitt";
import ParagraphKalenderMonat from "./paragraphs/kalender-monat/paragraph-kalender-monat";
import ParagraphAudio from "./paragraphs/audio/paragraph-audio";
import ParagraphLogosPartners from "./paragraphs/logos-partners/logos-partners";
import ParagraphTeaserListEvents from "./paragraphs/teaserliste/events/paragraph-teaser-list-events";
import ParagraphFormular from "./paragraphs/webform/paragraph-formular";
import ParagraphStellenangebote from "./paragraphs/stellenangebote/paragraph-stellenangebote";
import ParagraphIntroVideo from "./paragraphs/intro-video/paragraph-intro-video";

import ErrorBoundary from "../../error-boundary";
import ParagraphTeaserListPages from "./paragraphs/teaserliste/pages/paragraph-teaser-list-pages";
import ParagraphExtendedTeaserOverviewProjects from "./paragraphs/extended-teaser-list/projects/paragraph-extended-teaser-overview-projects";

class ContentBase extends Component {
  render() {
    return (
      <section className={`content-wrap ${this.props.className}`}>
        {this.props.content.map((item, index) =>
          // eslint-disable-next-line complexity
          (() => {
            let paragraphItem = item.entity;

            if (paragraphItem.entityBundle === "from_library") {
              paragraphItem =
                item.entity.fieldReusableParagraph.entity.paragraphs.entity;
            }

            switch (paragraphItem.entityBundle) {
              case "content_cloud":
                return (
                  <ErrorBoundary key={index}>
                    <ContentCloud content={paragraphItem} />
                  </ErrorBoundary>
                );
              case "stellenangebote":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphStellenangebote content={paragraphItem} />
                  </ErrorBoundary>
                );
              case "text_and_speech":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphTextAndSpeech content={paragraphItem} />
                  </ErrorBoundary>
                );
              case "audio":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphAudio content={paragraphItem} />
                  </ErrorBoundary>
                );
              case "media":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphMedia content={paragraphItem} />
                  </ErrorBoundary>
                );
              case "hero_text":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphHeroText content={paragraphItem} />
                  </ErrorBoundary>
                );
              case "click_suggestions":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphClickSuggestions content={paragraphItem} />
                  </ErrorBoundary>
                );
              case "teaserliste":
              case "teaserliste_news":
              case "teaserliste_personen":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphTeaserList content={paragraphItem} />
                  </ErrorBoundary>
                );
              case "teaserliste_events":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphTeaserListEvents content={paragraphItem} />
                  </ErrorBoundary>
                );
              case "teaserliste_pages":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphTeaserListPages content={paragraphItem} />
                  </ErrorBoundary>
                );
              case "teaser_image_text":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphTeaserImage content={paragraphItem} />
                  </ErrorBoundary>
                );
              case "section_heading":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphSectionheading content={paragraphItem} />
                  </ErrorBoundary>
                );
              case "text":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphText content={paragraphItem} />
                  </ErrorBoundary>
                );
              case "bilder_slider":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphBilderSlider content={paragraphItem} />
                  </ErrorBoundary>
                );
              case "zahlen_und_fakten":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphNumberFacts content={paragraphItem} />
                  </ErrorBoundary>
                );
              case "teaser_slider":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphTeaserSlider content={paragraphItem} />
                  </ErrorBoundary>
                );
              case "formular":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphFormular content={item.entity} />
                  </ErrorBoundary>
                );
              case "newsletteranmeldung":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphNewsletteranmeldung content={paragraphItem} />
                  </ErrorBoundary>
                );
              case "text_akkordion":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphAkkordionText content={paragraphItem} />
                  </ErrorBoundary>
                );
              case "chatbot":
                return (
                  <ErrorBoundary key={index}>
                    <Eforkbot />
                  </ErrorBoundary>
                );
              case "teaser_sammlung_dossier_":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphDossier content={paragraphItem} />
                  </ErrorBoundary>
                );
              case "seitentitel":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphPageTitle content={paragraphItem} />
                  </ErrorBoundary>
                );
              case "call_to_action":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphCallToAction content={paragraphItem} />
                  </ErrorBoundary>
                );
              case "erweiterte_teaseruebersicht":
              case "teaseruebersicht_events":
              case "teaseruebersicht_personen": {
                const nodes = {};

                if (paragraphItem.fieldManuelleSortierung) {
                  nodes.loading = false;
                  nodes.nodeQuery = {};
                  nodes.nodeQuery.entities = paragraphItem.fieldBeitraege.map(
                    (node) => node.entity
                  );

                  return (
                    <ErrorBoundary key={index}>
                      <ParagraphExtendedTeaserOverview
                        content={paragraphItem}
                        nodes={nodes}
                      />
                    </ErrorBoundary>
                  );
                }

                return (
                  <ErrorBoundary key={index}>
                    <ParagraphExtendedTeaserOverview content={paragraphItem} />
                  </ErrorBoundary>
                );
              }
              case "teaseruebersicht_projekte": {
                const nodes = {};

                if (paragraphItem.fieldManuelleSortierung) {
                  nodes.loading = false;
                  nodes.nodeQuery = {};
                  nodes.nodeQuery.entities = paragraphItem.fieldBeitraege.map(
                    (node) => node.entity
                  );

                  return (
                    <ErrorBoundary key={index}>
                      <ParagraphExtendedTeaserOverviewProjects
                        content={paragraphItem}
                        nodes={nodes}
                      />
                    </ErrorBoundary>
                  );
                }

                return (
                  <ErrorBoundary key={index}>
                    <ParagraphExtendedTeaserOverviewProjects
                      content={paragraphItem}
                    />
                  </ErrorBoundary>
                );
              }
              case "veranstaltungsinformationen":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphCalendarInformation
                      content={paragraphItem}
                      nodeContent={this.props.nodeContent}
                    />
                  </ErrorBoundary>
                );
              case "personeninformationen":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphPersonenInformationen
                      content={paragraphItem}
                      nodeContent={this.props.nodeContent}
                    />
                  </ErrorBoundary>
                );
              case "download":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphDownload
                      content={paragraphItem}
                      nodeContent={this.props.nodeContent}
                    />
                  </ErrorBoundary>
                );
              case "zitat":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphZitat
                      content={paragraphItem}
                      nodeContent={this.props.nodeContent}
                    />
                  </ErrorBoundary>
                );
              case "download_schublade":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphDownloadSchublade
                      content={paragraphItem}
                      nodeContent={this.props.nodeContent}
                    />
                  </ErrorBoundary>
                );
              case "timeline":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphTimeline
                      content={paragraphItem}
                      nodeContent={this.props.nodeContent}
                    />
                  </ErrorBoundary>
                );
              case "kontakt":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphKontakt
                      content={paragraphItem}
                      nodeContent={this.props.nodeContent}
                    />
                  </ErrorBoundary>
                );
              case "strukturierte_liste":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphStrukturierteListe
                      content={paragraphItem}
                      nodeContent={this.props.nodeContent}
                    />
                  </ErrorBoundary>
                );
              case "kalender_doppel_einzel_highlight":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphKalenderEinzelDoppel content={paragraphItem} />
                  </ErrorBoundary>
                );
              case "kalenderabschnitt":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphKalenderAbschnitt content={paragraphItem} />
                  </ErrorBoundary>
                );
              case "kalendermonatsuebersicht":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphKalenderMonat content={paragraphItem} />
                  </ErrorBoundary>
                );
              case "logos_partners":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphLogosPartners content={paragraphItem} />
                  </ErrorBoundary>
                );
              case "intro_video":
                return (
                  <ErrorBoundary key={index}>
                    <ParagraphIntroVideo content={paragraphItem} />
                  </ErrorBoundary>
                );
              default:
                return null;
            }
          })()
        )}
      </section>
    );
  }
}

ContentBase.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        fieldReusableParagraph: PropTypes.object,
        entityBundle: PropTypes.string.isRequired,
        fieldManuelleSortierung: PropTypes.bool,
      }),
    })
  ),
  className: PropTypes.string,
  nodeContent: PropTypes.object,
};

export default ContentBase;
