import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import Image from "../../../../image/image";

const DossierLink = (props) => {
  if (props.link.routed) {
    return (
      <Link to={props.link.path} {...props}>
        {props.children}
      </Link>
    );
  }

  return (
    <a href={props.link.path} {...props}>
      {props.children}
    </a>
  );
};

// inner layout of the teaser component
const DossierParagraphTeaser = (props) => {
  // Set --bg-img css propert
  const content = {
    bgImgUrl: props.content.fieldImage?.entity.fieldMediaImage.style.url,
    bgImgFormat: props.content.fieldImageFormat || "cover",
    bgColor: props.content.fieldBackgroundColor?.color,
    title: props.content.fieldTitle,
    text: props.content.fieldText?.processed,
    link: {
      ...props.content.fieldLink?.url,
      label: props.content.fieldLink?.title,
    },
  };

  if (props.content.fieldReferencedContentEnabled) {
    const referencedContent = props.content.fieldReferencedContent.entity;

    content.bgImgUrl =
      referencedContent.fieldTeaserbild?.entity.fieldMediaImage.style.url;
    content.bgImgFormat = "cover";
    content.title = referencedContent.title;
    content.text = referencedContent.fieldTeasertext?.processed;
    content.link = {
      ...referencedContent.entityUrl,
      label: "Mehr erfahren",
    };
  }

  const bgImgProp = {};
  content.bgImgUrl && (bgImgProp["--bg-img"] = `url("${content.bgImgUrl}")`);
  content.bgColor && (bgImgProp["--bg-color"] = content.bgColor);

  if (content) {
    return (
      <DossierLink
        link={content.link}
        className={classNames({
          "dossier-paragraph": true,
          [`bg-${content.bgImgFormat}`]: true,
        })}
        style={bgImgProp}
      >
        {content.bgImgFormat === "cover" && content.bgImgUrl && (
          <div className="bg-img" />
        )}
        {content.title && (
          <div className="paragraph-header">
            {content.title && <h4>{content.title}</h4>}
            {content.text && content.link.href && (
              <div dangerouslySetInnerHTML={{ __html: content.text }} />
            )}
          </div>
        )}
        {content.bgImgFormat === "contain" &&
          props.content.fieldImage &&
          !props.content.fieldReferencedContentEnabled && (
            <div className="paragraph-img">
              <Image data={props.content.fieldImage.entity.fieldMediaImage} />
            </div>
          )}
        {content.link.label && (
          <div className="paragraph-footer">
            <DossierLink link={content.link}>{content.link.label}</DossierLink>
          </div>
        )}
        {!content.link.label && content.text && (
          <div className="paragraph-footer">
            <div dangerouslySetInnerHTML={{ __html: content.text }} />
          </div>
        )}
      </DossierLink>
    );
  }
};

export default DossierParagraphTeaser;
