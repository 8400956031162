import React, { Component } from "react";
import PropTypes from "prop-types";

import GridItem from "./grid-item";

class GridView extends Component {
  state = {
    entities: this.props.entities,
  };

  render() {
    return (
      <section id="grid-section">
        <div className="container-fluid">
          <div className={`row sort-by-${this.state.sorting}`}>
            {this.state.entities.map((item, index) => (
              <GridItem key={index} index={index} content={item} />
            ))}
          </div>
        </div>
      </section>
    );
  }
}

GridView.propTypes = {
  entities: PropTypes.array.isRequired,
};

export default GridView;
