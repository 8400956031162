import React, { Component } from "react";
import { Link } from "react-router-dom";
import { self } from "../config";

class LogoButton extends Component {
  render() {
    return (
      <Link className="site-logo-button" to="/">
        <img src={`${self}/GEDISA_logo_RGB_+claim_breit_farbig.png`} alt={"Gedisa Logo"}/>
      </Link>
    );
  }
}

LogoButton.propTypes = {};

export default LogoButton;
