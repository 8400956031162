import React, { Component } from "react";
import PropTypes from "prop-types";
import memoize from "memoize-one";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";

// Router
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";

// Redux
import { connect } from "react-redux";

// Page Components
import Landingpage from "./landingpage/landingpage";
import Projekt from "./projekt/projekt";
import News from "./news/news";
import Event from "./event/event";
import Person from "./person/person";
import footerConfigQuery from "footer-config-query.graphql";
import PageFooter from "./footer/page-footer";
import menuQuery from "./general-components/menu-query.graphql";
import SimpleHeader from "./header/simple-header";
import ErrorBoundary from "./error-boundary";
import NodeRevision from "./backend/NodeRevision";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {Object} - Relevant Data for App Component from Redux Store.
 */
const mapStateToProps = (reduxStore) => ({ reduxStore });

/**
 * @todo 404.
 * @todo Fetch Page title and push to store. Use for <title> tag.
 * @todo rem-calc() isn´t working at all
 * @todo delete fields in drupal for modules that are only used in ms-ggb, and add hints for fields that dont do anything in either ms or regular site in modules used in both.
 */
class App extends Component {
  /**
   * Generate Base Class names for main wrapper.
   * @see https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
   *
   * @param {string} pathname - Current Path
   * @returns {string} - Base Classes
   */
  generateMainClasses = memoize((pathname) => {
    const pathNameSplitted = pathname.split("/");

    // Remove first (empty) item
    pathNameSplitted.shift();

    return `${pathNameSplitted[0]} ${pathNameSplitted
      .splice(1, pathNameSplitted.length)
      .join("-")}`;
  });

  componentDidMount() {
    document.body.className += `${this.generateMainClasses(
      this.props.location.pathname
    )}`;
  }

  componentDidUpdate(prevProps) {
    // Scroll to top on route change
    if (
      this.props.location !== prevProps.location &&
      !this.props.location.hash
    ) {
      window.scrollTo(0, 0);
      document.body.className = `${this.generateMainClasses(
        this.props.location.pathname
      )}`;
    }
  }

  render() {
    return (
      <div className="main-content">
        <ErrorBoundary>
          <SimpleHeader location={this.props.location}/>
        </ErrorBoundary>

        <main className="main-page-content">
          <ErrorBoundary>
            <Switch>
              <Route exact path="/page/:alias" component={Landingpage} />
              <Route exact path="/projekt/:alias" component={Projekt} />
              <Route exact path="/news/:alias" component={News} />
              <Route exact path="/veranstaltung/:alias" component={Event} />
              <Route exact path="/person/:alias" component={Person} />
              <Route
                exact
                path="/node/:nodeId/revisions/:revisionId/view"
                component={NodeRevision}
              />
              <Route exact path="/" component={Landingpage} />
            </Switch>
          </ErrorBoundary>
        </main>

        <footer id="pageFooter">
          <ErrorBoundary>
            <PageFooter
              footerConfig={this.props.footerConfig.configPagesById}
              menu={this.props.menuQuery.menuByName}
            />
          </ErrorBoundary>
        </footer>
      </div>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  footerConfig: PropTypes.object.isRequired,
  menuQuery: PropTypes.object.isRequired,
  reduxStore: PropTypes.object,
  location: PropTypes.object.isRequired,
};

export default compose(
  graphql(footerConfigQuery, {
    name: "footerConfig",
  }),
  graphql(menuQuery, {
    options: {
      variables: { name: "footer" },
    },
    name: "menuQuery",
  })
)(withRouter(connect(mapStateToProps)(App)));
