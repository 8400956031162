import React from "react";
import Link from "../../../../link/link";

// inner layout of the fact component
const DossierParagraphFact = ({ content }) => {
  // Set --bg-img css property if image exists, for img url access in css
  const bgImgUrl = content.fieldImage?.entity.fieldMediaImage.style.url;
  const bgImgProp = bgImgUrl && {
    "--bg-img": `url("${bgImgUrl}")`,
  };

  return (
    <a href={content.fieldLink.url.path} className="dossier-paragraph">
      {content.fieldImage && <div className="bg-img" style={bgImgProp} />}
      <div className="paragraph-header">
        {content.fieldTitle && <h1>{content.fieldTitle}</h1>}
        {content.fieldLabel && <div>{content.fieldLabel}</div>}
      </div>
      <div className="paragraph-footer">
        {content.fieldText && (
          <div
            dangerouslySetInnerHTML={{ __html: content.fieldText.processed }}
          />
        )}
        {content.fieldLink && <Link link={content.fieldLink} />}
      </div>
    </a>
  );
};

export default DossierParagraphFact;
