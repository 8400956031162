import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link32 } from "@carbon/icons-react"

class ParagraphSectionheading extends Component {

  componentDidMount() {
    setTimeout(() => {
      if (window.location.hash && (window.location.hash.replace('#', '') === this.props.content.fieldHeading.toLowerCase().replace(/\W/g, '-'))) {
        let anchorLink = document.getElementById(window.location.hash.replace('#', ''));

        if (anchorLink) {
          let scrollPosition = anchorLink.getBoundingClientRect().top + document.documentElement.scrollTop - 250;

          window.scrollTo({
            top: scrollPosition,
            behavior: 'smooth'
          });
        }
      }
    }, 250);
  }

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-sectionheading": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-6 col-sm-2 col-md-2 col-lg-2">
              <nobr>
                <h2
                  id={this.props.content.fieldHeading
                    .toLowerCase()
                    .replace(/\W/g, "-")}
                  className="section-heading"
                >
                  {this.props.content.fieldHeading}
                </h2>
              </nobr>
            </div>
            <div className="col-10 col-sm-10 col-md-14 col-lg-14 line"/>
            {this.props.content.fieldSetAnchorLink && (
              <div className="col-1s">
                <a
                  className="anchor sr-only"
                  href={`#${this.props.content.fieldHeading
                    .toLowerCase()
                    .replace(/\W/g, "-")}`}
                >
                  <Link32 />
                </a>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

ParagraphSectionheading.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldSetAnchorLink: PropTypes.bool
  })
};

export default ParagraphSectionheading;
