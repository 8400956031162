import React from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import Slider from "react-slick";
import { connect } from "react-redux";
import { compose } from "recompose";
import teaserNodeQuery from "../../../../teaser-base/queries/teaser-node-query.graphql";
import teaserNodeQueryTagFiltered from "../../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import LoadingIndicator from "../../../../loading-indicator";
import TeaserNews, {
  teaserNewsPropTypes,
} from "../../../../teaser-base/news/teaser-news";
import TeaserPerson, {
  teaserPersonPropTypes,
} from "../../../../teaser-base/person/teaser-person";
import { pagerFullPagesAction } from "../../../../../app-actions";
import {self} from "../../../../../config";
import { teaserEventPropTypes } from "../../../../teaser-base/event/teaser-event";
import { teaserGeneralPropTypes } from "../../../../teaser-base/general/teaser-general";
import { teaserProjectPropTypes } from "../../../../teaser-base/project/teaser-projekt";

class ComponentTeaserlistCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSlide: 0,
    };

    this.slider = React.createRef();
  }

  pushPagerFullPageConfig = () => {
    if (this.props.pagerFullPage && this.props.nodes.nodeQuery) {
      const pagerFullPagesConfig = {
        id: this.props.id,
        items:
          this.props.nodesConfig === "Manuell"
            ? this.props.manualNodes.map((item) => item.entity)
            : this.props.nodes.nodeQuery.entities,
        overviewLink: this.props.pagerFullPageOverviewLink,
      };

      this.props.dispatch(pagerFullPagesAction(pagerFullPagesConfig));
    }
  };

  componentDidMount() {
    // Pager on full screen pages.
    this.pushPagerFullPageConfig();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.nodes.nodeQuery) !==
      JSON.stringify(this.props.nodes.nodeQuery)
    ) {
      this.pushPagerFullPageConfig();
    }
  }

  render() {
    const sliderSettings = {
      adaptiveHeight: false,
      arrows: false,
      centerMode: false,
      dots: false,
      infinite: false,
      slidesToShow: 2.8,
      slidesToScroll: 1,
      touchMove: true,
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 2.8,
          },
        },
        {
          breakpoint: 1450,
          settings: {
            slidesToShow: 2.26,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1.66,
            touchMove: true,
          },
        },
        {
          breakpoint: 786,
          settings: {
            slidesToShow: 1.66,
            touchMove: true,
          },
        },
        {
          breakpoint: 550,
          settings: {
            slidesToShow: 1.2,
            touchMove: true,
          },
        },
      ],
    };

    if (this.props.nodes.loading) {
      return false;
    }

    return (
      <>
        {(this.props.nodesConfig === "Manuell" &&
          this.props.manualNodes.length >= 4) ||
        (this.props.nodesConfig !== "Manuell" &&
          this.props.nodes.nodeQuery &&
          this.props.nodes.nodeQuery.entities.length >= 4) ? (
          <>
            {this.props.nodesConfig === "Manuell" ? (
              <Slider {...sliderSettings}>
                {this.props.manualNodes.map((item, index) => (
                  <React.Fragment key={index}>
                    {(() => {
                      switch (item.entity.entityBundle) {
                        case "news":
                          return (
                            <TeaserNews
                              item={item.entity}
                              pagerFullPage={this.props.pagerFullPage}
                            />
                          );
                        case "person":
                          return (
                            <TeaserPerson
                              item={item.entity}
                              pagerFullPage={this.props.pagerFullPage}
                            />
                          );
                        default:
                          return null;
                      }
                    })()}
                  </React.Fragment>
                ))}
              </Slider>
            ) : (
              <>
                {this.props.nodes.nodeQuery &&
                this.props.nodes.nodeQuery.entities.length > 0 ? (
                  <>
                    <div className="title">
                      <div className="head-wrap">
                        <div className="title-wrapper">
                          <div className="container">
                            <div className={"row"}>
                              <div className="col-6 col-sm-4 col-md-3 col-lg-2">
                                <h2 id={"aktuelles"}>Aktuelles</h2>
                              </div>
                              <div className="col-6 col-sm-9 col-md-11 col-lg-13 d-lg-flex progress-bar-container">
                                <div className="progress-bar ">
                                  {[...Array(this.props.nodes.nodeQuery.entities.length)].map((item, index) => (
                                    <button className="dot d-lg-flex d-none" key={index} onClick={() => this.slider.current.slickGoTo(index)}>{index}</button>
                                  ))}
                                </div>
                                <div
                                  className="progress-slider d-lg-flex d-none"
                                  style={{
                                    width: `${(1 / this.props.nodes.nodeQuery.entities.length) * 100}%`,
                                    marginLeft: `${(this.state.activeSlide / this.props.nodes.nodeQuery.entities.length) * 100}%`
                                  }}>
                                </div>
                              </div>
                              <div className="col-4 col-sm-3 col-md-2 col-lg-1 control-wrapper">
                                <button className="arrow-prev" onClick={() => this.slider.current.slickGoTo(this.state.activeSlide - 1)}>
                                  <img src={`${self}/arrow-left.svg`}/>
                                </button>
                                <button className="arrow-next" onClick={() => this.slider.current.slickGoTo(this.state.activeSlide + 1)}>
                                  <img src={`${self}/arrow-right.svg`}/>
                                </button>
                              </div>
                            </div>
                          </div>
                          </div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="row">
                        <div className="col-16 col-lg-14 offset-lg-2">
                          <Slider {...sliderSettings} ref={this.slider}>
                            {this.props.nodes.nodeQuery.entities.map(
                              (item, index) => (
                                <React.Fragment key={index}>
                                  {(() => {
                                    switch (item.entityBundle) {
                                      case "news":
                                        return (
                                          <TeaserNews
                                            item={item}
                                            pagerFullPage={this.props.pagerFullPage}
                                          />
                                        );
                                      case "person":
                                        return (
                                          <TeaserPerson
                                            item={item}
                                            pagerFullPage={this.props.pagerFullPage}
                                          />
                                        );
                                      default:
                                        return null;
                                    }
                                  })()}
                                </React.Fragment>
                              )
                            )}
                          </Slider>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <LoadingIndicator />
                )}
              </>
            )}
          </>
        ) : null}
      </>
    );
  }
}

ComponentTeaserlistCarousel.propTypes = {
  count: PropTypes.number.isRequired,
  manualNodes: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.oneOfType([
        teaserNewsPropTypes,
        teaserEventPropTypes,
        teaserPersonPropTypes,
        teaserGeneralPropTypes,
        teaserProjectPropTypes,
      ]),
    })
  ),
  id: PropTypes.string.isRequired,
  nodesConfig: PropTypes.oneOf(["Automatisch (chronologisch)", "Manuell"]),
  type: PropTypes.oneOf(["news", "person", "veranstaltung", "all"]),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      targetId: PropTypes.string,
    })
  ),
  pagerFullPageOverviewLink: PropTypes.object,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
  nodes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default compose(
  graphql(teaserNodeQuery, {
    name: "nodes",
    skip: (props) => props.nodesConfig === "Manuell" || props.tags.length > 0,
    options: (props) => ({
      variables: {
        limit: props.count ? props.count : 100,
        type: props.type === "all" ? ["news", "person"] : [props.type],
      },
    }),
  }),
  graphql(teaserNodeQueryTagFiltered, {
    name: "nodes",
    skip: (props) => props.nodesConfig === "Manuell" || props.tags.length === 0,
    options: (props) => ({
      variables: {
        limit: props.count ? props.count : 100,
        type: props.type === "all" ? ["news", "person"] : [props.type],
        tags: props.tags.map((item) => item.targetId.toString()),
      },
    }),
  })
)(connect()(ComponentTeaserlistCarousel));
