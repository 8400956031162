import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class ParagraphPageTitle extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-page-title": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className="col-16 col-lg-12 offset-lg-2">
              <h1>{this.props.content.fieldHeading}</h1>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphPageTitle.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired
  }),
};

export default ParagraphPageTitle;
