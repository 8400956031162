import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import ErrorBoundary from "../../../../error-boundary";
import {self} from "../../../../config";

class ParagraphAkkordionText extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-akkordion-text": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className="col-16 col-lg-14 offset-lg-2">
              <Accordion>
                {this.props.content.fieldAbschnitte.map((item, index) => (
                  <ErrorBoundary key={index}>
                    <div className="accordion-item">
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <h2 className="title">{item.entity.fieldTitel}</h2>
                            <img src={`${self}/arrow-right.svg`} alt={"mehr erfahren"}/>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div
                            className="text"
                            dangerouslySetInnerHTML={{
                              __html: item.entity.fieldText.processed,
                            }}
                          />
                        </AccordionItemPanel>
                      </AccordionItem>
                    </div>
                  </ErrorBoundary>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphAkkordionText.propTypes = {
  content: PropTypes.shape({
    fieldAbschnitte: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldTitel: PropTypes.string,
          fieldText: PropTypes.shape({
            processed: PropTypes.string,
          }),
        }),
      })
    ),
  }),
};

export default ParagraphAkkordionText;
