import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Slider from "react-slick";
import { ArrowRight32, ArrowLeft32 } from "@carbon/icons-react";
import Link from "../../../link/link";
import { ErrorBoundary } from "carbon-components-react";
import { self } from "../../../../config";
import { connect } from "react-redux";
import Image from "../../../image/image";

const mapStateToProps = (reduxStore) => ({
  microsite: reduxStore.appStore.microsite,
});

class ParagraphTeaserSlider extends Component {
  state = {
    showMore: false,
    sliderSettings: {
      arrows: false,
      fade: true,
      autoplay: true,
      infinite: true,
      dots: true,
      nextArrow: <ArrowRight32 width={48} height={48} viewBox="0 0 32 24" />,
      prevArrow: <ArrowLeft32 width={48} height={48} viewBox="0 0 32 24" />,
      slidesToShow: 1,
      slidesToScroll: 1,
      touchMove: true,
      pauseOnHover: true,
    },
  };

  showMore = (index) => {
    this.setState({
      showMore: index,
    });
  };

  hideMore = (index) => {
    this.setState({
      showMore: false,
    });
  };

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-teaser-slider": true,
    });
    if (this.props.microsite) {
      return (
        <section className={sectionClassNames}>
          <article className="container full-size main-info">
            <Image
              className="background-image"
              data={
                this.props.content.fieldTeaserSlides[0].entity.fieldImage.entity
                  .fieldMediaImage
              }
            />
            <div className="container">
              <div className="row">
                <div
                  className={classNames({
                    "col-16 col-md-8": this.props.microsite,
                    "col-16 col-lg-10 ": !this.props.microsite,
                  })}
                >
                  {this.props.content.fieldTeaserSlides[0].entity
                    .fieldTeaserueberschrift && (
                    <h3 className="headline">
                      {" "}
                      {
                        this.props.content.fieldTeaserSlides[0].entity
                          .fieldTeaserueberschrift
                      }
                    </h3>
                  )}
                  {this.props.content.fieldTeaserSlides[0].entity
                    .fieldAusfuehrlicherText && (
                    <div className="extra-text">
                      <div className="inner-wrapper">
                        <div
                          className="text"
                          dangerouslySetInnerHTML={{
                            __html:
                            this.props.content.fieldTeaserSlides[0].entity
                              .fieldAusfuehrlicherText.processed,
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </article>
        </section>
      );
    } else {
      return (
        <section className={sectionClassNames}>
          {/*{this.props.content.fieldCtas &&
          <div className="button-wrapper">
            <div className={"container narrow"}>
              <div className="row">
                <div className="col-16">
                  {this.props.content.fieldCtas.map((item, index) => (
                    <button className="btn btn-primary">
                      {item.title}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        }*/}
          <ErrorBoundary>
            <Slider {...this.state.sliderSettings}>
              {this.props.content.fieldTeaserSlides.map((item, index) => (
                <React.Fragment key={index}>
                  <div
                    className={`custom-slide-wrapper ${
                      this.state.showMore === index ? "active" : ""
                    }`}
                  >
                    {/*<ErrorBoundary>
                    <Image
                      className="background-image"
                      data={item.entity.fieldImage.entity.fieldMediaImage}
                    />
                  </ErrorBoundary>*/}
                    <article className="container main-info">
                      <div className="row">
                        <div className="col-16 col-md-10 offset-md-3 position-static slide-inner">
                          {item.entity.fieldTeaserueberschrift ? (
                            <h3 key={index} data-index={index + 1}>
                              {" "}
                              {item.entity.fieldTeaserueberschrift}
                            </h3>
                          ) : (
                            <h3>
                              {item.entity.fieldInhaltLink.url.entity.title}
                            </h3>
                          )}
                        </div>
                        <div className="col-16 d-flex align-items-center justify-content-center">
                          {item.entity.fieldAusfuehrlicherText && (
                            <>
                              <button
                                className="btn btn-primary more"
                                onClick={() => this.showMore(index)}
                              >
                                <img src={`${self}/arrow-right.svg`} /> mehr
                                erfahren
                              </button>
                            </>
                          )}
                        </div>
                        {item.entity.fieldAusfuehrlicherText && (
                          <div
                            className={`more-info ${
                              this.state.showMore === index ? "active" : ""
                            }`}
                          >
                            <div className="inner-wrapper">
                              <div
                                className="text"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    item.entity.fieldAusfuehrlicherText
                                      .processed,
                                }}
                              />
                              <button
                                className="btn btn-primary"
                                onClick={() => this.hideMore()}
                              >
                                <img src={`${self}/close.svg`} />
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </article>
                  </div>
                </React.Fragment>
              ))}
            </Slider>
          </ErrorBoundary>
        </section>
      );
    }
  }
}

ParagraphTeaserSlider.propTypes = {
  content: PropTypes.shape({
    fieldTeaserSlides: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldTeaserueberschrift: PropTypes.string.isRequired,
          fieldImage: PropTypes.shape({
            entity: PropTypes.shape({
              fieldMediaImage: PropTypes.shape({
                alt: PropTypes.string,
                title: PropTypes.string,
                style: PropTypes.shape({
                  url: PropTypes.string.isRequired,
                }),
              }),
            }),
          }),
          fieldInhaltLink: PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.shape({
              routed: PropTypes.bool,
              path: PropTypes.string,
              entity: PropTypes.shape({
                title: PropTypes.string,
              }),
            }),
          }),
        }),
      })
    ),
  }),
};

export default connect(mapStateToProps)(ParagraphTeaserSlider);
