import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import TeaserNews, {
  teaserNewsPropTypes,
} from "../../../teaser-base/news/teaser-news";
import TeaserPerson, {
  teaserPersonPropTypes,
} from "../../../teaser-base/person/teaser-person";
import ErrorBoundary from "../../../../error-boundary";
import DossierParagraphTeaser from "./paragraphs/dossier-paragraph-teaser";
import DossierParagraphFact from "./paragraphs/dossier-paragraph-fact";

const ParagraphDossier = (props) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-dossier": true,
    "with-background-image": props.content.fieldImage !== null,
  });

  /**
   * Transform an array into chunks and assign a layout pattern to each chunk.
   */
  const getParagraphChunks = (paragraphs) => {
    /**
     * Split an array into multiple chunks
     * @param {Object} array The array that needs to be split into chunks
     * @param {Number} chunkSize The size of elements each chunk should have
     */
    const generateChunks = (array, chunkSize) => {
      const out = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        out.push(chunk);
      }
      return out;
    };

    // generate chunks from paragraphs (3 paragraphs/chunk)
    const paragraphChunks = generateChunks(paragraphs, 3);

    // assign layout patterns as array to it (numbers are columns)
    const out = paragraphChunks.map((chunk, i) => {
      let pattern;
      switch (chunk.length) {
        case 3:
          if (i % 2) {
            pattern = [8, 4, 4];
          } else {
            pattern = [4, 4, 8];
          }
          break;
        case 2:
          pattern = [8, 8];
          break;
        case 1:
          pattern = [16];
          break;
      }
      return { chunk, pattern };
    });

    return out;
  };

  console.log(props);

  return (
    <section className={sectionClassNames}>
      {props.content.fieldHeading && (
        <div className="container header">
          <div className="row">
            <div className="col-16">
              <h2>{props.content.fieldHeading}</h2>
            </div>
          </div>
        </div>
      )}

      <div className="container">
        <div className="row items">
          {getParagraphChunks(props.content.fieldParagraph, 3).map(
            (chunk, i) => (
              <React.Fragment key={i}>
                {chunk.chunk.map((paragraph, j) => (
                  <div
                    key={j}
                    className={classNames({
                      "col-16": chunk.pattern[j] === 16,
                      "col-16 col-lg-8": chunk.pattern[j] === 8,
                      "col-8 col-lg-4": chunk.pattern[j] === 4,
                    })}
                  >
                    {paragraph.entity.type.targetId ===
                      "dossier_paragraph_teaser" && (
                      <DossierParagraphTeaser content={paragraph.entity} />
                    )}
                    {paragraph.entity.type.targetId ===
                      "dossier_paragraph_fact" && (
                      <DossierParagraphFact content={paragraph.entity} />
                    )}
                  </div>
                ))}
              </React.Fragment>
            )
          )}
        </div>
      </div>
    </section>
  );
};

ParagraphDossier.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    fieldImage: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
    }),
    fieldBeitraegeDossier: PropTypes.arrayOf(
      PropTypes.oneOfType([teaserNewsPropTypes, teaserPersonPropTypes])
    ),
  }),
};

export default ParagraphDossier;
