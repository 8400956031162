import React from "react";
import { useSpring, animated, config } from "react-spring";
import PropTypes from "prop-types";

const StoryTeaserContent = ({
  inView,
  contentWrapper,
  inViewRef,
  content,
  transitionXRight,
  transitionXLeft,
  entry,
  dialogeRight,
  dialogElement,
  hover,
  setHoverState,
  right,
  left,
}) => {
  const { x } = useSpring({
    from: { x: 0 },
    x: hover ? 1 : 0,
    config: { ...config.wobbly, tension: left || right ? 120 : 180 },
  });

  let style = {
    scale: x.to({
      output: [1, 1.5],
      range: [0, 1.2],
    }),
    rotate: x.to({
      output: ["0deg", "1deg", "0deg"],
      range: [0, 0.5, 1],
    }),
  };

  /*if (left) {
    style = {
      ...style,
      translateX: x.to({
        output: ['0', `${transitionXLeft}px`],
        range: [0, 1]
      })
    };
  }

  if (right) {
    style = {
      ...style,
      translateX: x.to({
        output: ['0', `-${transitionXRight}px`],
        range: [0, 1]
      })
    };
  }*/

  return (
    <div
      onMouseEnter={setHoverState}
      onMouseLeave={setHoverState}
      className={`cloud-content-teaser ${inView ? "in-view" : "not-in-view"}`}
    >
      <animated.div style={style} ref={contentWrapper}>
        <div className="inner-wrapper">
          {content.fieldBild && (
            <img
              src={content.fieldBild.entity.fieldMediaImage.style.url}
              alt=""
            />
          )}
          {content.entityLabel && (
            <div className="teaser-text">
              <h2>{content.entityLabel}</h2>
            </div>
          )}
          {content.fieldDialogtexte && (
            <div
              ref={dialogElement}
              className={`story-dialoge ${dialogeRight ? "to-right" : ""} ${
                hover ? "visible-story" : ""
              }`}
              dangerouslySetInnerHTML={{
                __html: content.fieldDialogtexte.value,
              }}
            />
          )}
        </div>
      </animated.div>
    </div>
  );
};

StoryTeaserContent.propTypes = {
  content: PropTypes.object.isRequired,
  inView: PropTypes.bool.isRequired,
  entry: PropTypes.object,
  dialogElement: PropTypes.object.isRequired,
  contentWrapper: PropTypes.object.isRequired,
  hover: PropTypes.bool.isRequired,
  setHoverState: PropTypes.func.isRequired,
  right: PropTypes.bool.isRequired,
  left: PropTypes.bool.isRequired,
  dialogeRight: PropTypes.bool.isRequired,
  transitionXRight: PropTypes.number.isRequired,
  transitionXLeft: PropTypes.number.isRequired,
};

export default StoryTeaserContent;
