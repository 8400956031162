import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";

/**
 * A module representing a text paragraph.
 */
const mapStateToProps = (reduxStore) => ({
  microsite: reduxStore.appStore.microsite,
});
class ParagraphText extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-text": true,
      "background-color": this.props.content.fieldFarbigHinterlegen,
    });
    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div
              className={classNames({
                "col-wrapper col-16": true,
                "col-md-12 offset-md-2 col-xl-8 offset-xl-4":
                  this.props.microsite,
                "col-lg-14 offset-lg-2": !this.props.microsite,
              })}
            >
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: this.props.content.fieldText.processed,
                }}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphText.propTypes = {
  /**
   * The modules content.
   */
  content: PropTypes.shape({
    fieldFarbigHinterlegen: PropTypes.bool,
    fieldText: PropTypes.shape({
      processed: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(ParagraphText);
