import React from "react";
import PropTypes from "prop-types";
import YouTube from "react-youtube";

const YouTubeVideo = (props) => {
  return (
    <section className={props.sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16 col-lg-12 offset-lg-2">
            <div className="iframe-wrap iframe-16-9">
              <YouTube videoId={props.content.fieldYoutubeVideoUrl.videoId} />
              <iframe
                width="560"
                height="315"
                src={`https://www.youtube-nocookie.com/embed/${props.content.fieldYoutubeVideoUrl.videoId}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                rel={0}
                playsInline
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

YouTubeVideo.propTypes = {
  content: PropTypes.shape({
    /**
     * 0 = 100%, 1 = 50%
     */
    fieldGroesse: PropTypes.oneOf(["0", "1"]),
    fieldYoutubeVideoUrl: PropTypes.shape({
      videoId: PropTypes.string.isRequired,
    }),
  }),
  sectionClassNames: PropTypes.string,
};

export default YouTubeVideo;