import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import LoadingIndicator from "../general-components/loading-indicator";
import ContentBase from "../general-components/content-base/content-base";
import eventPageQuery from "./event.graphql";
import PagerFullPage from "../general-components/pager-full-page";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { i18nActionHelper } from "../i18n/i18n";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{pagerConfig: ([]|*[])}} - Relevant Data for App Component from
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  pagerConfig: reduxStore.appStore.pagerFullPages,
});

class Event extends Component {
  state = {
    queryStrings: queryString.parse(this.props.location.search),
  };

  componentDidMount() {
    this.setState({
      queryStrings: queryString.parse(this.props.location.search),
    });

    i18nActionHelper(this.props, this.props.dispatch);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.location) !== JSON.stringify(this.props.location)
    ) {
      this.setState({
        queryStrings: queryString.parse(this.props.location.search),
      });
    }

    if (
      JSON.stringify(this.props.data.route) !==
      JSON.stringify(prevProps.data.route)
    ) {
      i18nActionHelper(this.props, this.props.dispatch);
    }
  }

  render() {
    if (this.props.data.route) {
      const published = this.props.data.route.entity.status
        ? "node-published"
        : "node-unpublished";

      return (
        <article
          className={`node-${this.props.data.route.entity.entityBundle} node-${this.props.data.route.entity.entityId} node-full-page ${published}`}
        >
          <Helmet>
            <title>{`GEDISA | ${this.props.data.route.entity.entityLabel}`}</title>
          </Helmet>

          <ContentBase
            content={this.props.data.route.entity.fieldModules}
            nodeContent={this.props.data.route.entity}
          />

          {this.state.queryStrings.p &&
            this.props.pagerConfig.filter(
              (config) => config.id === this.state.queryStrings.p
            ).length > 0 && (
              <PagerFullPage
                config={
                  this.props.pagerConfig.filter(
                    (config) => config.id === this.state.queryStrings.p
                  )[0]
                }
                currentId={this.props.data.route.entity.entityId}
              />
            )}
        </article>
      );
    }

    return <LoadingIndicator />;
  }
}

Event.propTypes = {
  data: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  pagerConfig: PropTypes.object,
};

export default graphql(eventPageQuery, {
  options: (props) => ({
    variables: { path: props.location.pathname },
  }),
})(connect(mapStateToProps)(withRouter(Event)));
