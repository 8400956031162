import React from "react";
import PropTypes from "prop-types";
import ContentBase from "../../content-base";

const CloudDetailContent = ({
  inView,
  contentWrapper,
  inViewRef,
  content,
  transitionXRight,
  transitionXLeft,
  entry,
  dialogeRight,
  dialogElement,
  hover,
  setHoverState,
  right,
  left,
}) => {
  return (
    <div className="cloud-detail-overlay">
      <div className="cloud-detail-inner">
        <div className="cloud-detail-content">
          {/*content.fieldBild &&
            <img src={content.fieldBild.entity.fieldMediaImage.style.url} alt=""/>
          */}
          {content.entityLabel && (
            <div className="title">
              <h2>{content.entityLabel}</h2>
            </div>
          )}

          {content.fieldModules && (
            <ContentBase content={content.fieldModules} />
          )}

          {content.fieldDialogtexte && (
            <div
              ref={dialogElement}
              className={`story-dialoge ${dialogeRight ? "to-right" : ""} ${
                hover ? "visible-story" : ""
              }`}
              dangerouslySetInnerHTML={{
                __html: content.fieldDialogtexte.value,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

CloudDetailContent.propTypes = {
  content: PropTypes.object.isRequired,
};

export default CloudDetailContent;
