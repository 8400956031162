import React from "react";
import PropTypes from "prop-types";
import { Play24, Pause24 } from "@carbon/icons-react";
import classNames from "classnames";
import {self} from "../../../../config";

class ComponentVideo extends React.Component {
  render() {
    return (
      <section className={"paragraph intro-video"}>
        {Boolean(this.props.content.fieldVideo) && (
          <>
            <video
              src={
                this.props.content.fieldVideo.entity
                  .fieldMediaVideoFile.entity.url
              }
              controls={false}
              muted={true}
              loop={true}
              autoPlay={true}
              playsInline={true}
            />
          </>
        )}
      </section>
    );
  }
}

ComponentVideo.propTypes = {
  content: PropTypes.shape({
    fieldVideo: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaVideoFile: PropTypes.shape({
          entity: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        }),
      }),
    }),
  }),
  sectionClassNames: PropTypes.string,
};

export default ComponentVideo;