import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Link from "../../../link/link";
import VisibilitySensor from 'react-visibility-sensor';

import Image from "../../../image/image";
import {self} from "../../../../config";

class ParagraphTeaserImage extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-teaser-image": true,
    });

    return (
      <section className={sectionClassNames}>
        <VisibilitySensor overlay={true}>
          {(isVisible) =>
            <div className="container">
              <div
                className={classNames({
                  row: true,
                  "flex-row-reverse":
                    this.props.content.fieldImagePosition === "right",
                })}
              >

                <div
                  className={classNames({
                    "col-16 col-lg-6 image-col": true,
                    "offset-lg-2": this.props.content.fieldImagePosition === "left"
                  })}
                >


                  {this.props.content.fieldImage && !this.props.content.fieldStackedImages &&
                    <div className="rotate-img">
                      <Image
                        data={this.props.content.fieldImage.entity.fieldMediaImage}
                        nodeTitle={this.props.content.fieldHeading}
                      />
                    </div>
                  }
                  {this.props.content.fieldStackedImages &&
                    <div className={`stacked-images ${isVisible ? 'visible' : 'not-visible'}`}>
                      {this.props.content.fieldStackedImages.map((image, index) => (
                        <React.Fragment key={index}>
                          <div className="animation-wrapper">
                            <Image
                              data={image.entity.fieldMediaImage}
                            />
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  }
                </div>

                <div
                  className={classNames({
                    "col-16 col-lg-8 text-col": true,
                    "offset-lg-2": this.props.content.fieldImagePosition === "right"
                  })}
                >
                  <h2>{this.props.content.fieldHeading}</h2>
                  {this.props.content.fieldText && (
                    <div
                      className="text"
                      dangerouslySetInnerHTML={{
                        __html: this.props.content.fieldText.value,
                      }}
                    />
                  )}
                  {this.props.content.fieldCallToAction && (
                    <Link
                      className="btn btn-primary with-arrow"
                      link={this.props.content.fieldCallToAction}
                    >
                      <img className="link-arrow" alt="link arrow" src={`${self}/arrow-down-right.svg`}/>{this.props.content.fieldCallToAction.title}
                    </Link>
                  )}
                </div>


              </div>
            </div>
          }
        </VisibilitySensor>
      </section>
    );
  }
}

ParagraphTeaserImage.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldImage: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        }),
      }),
    }),
    fieldCallToAction: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.string,
      }),
    }),
    fieldText: PropTypes.shape({
      processed: PropTypes.string,
    }),
    fieldImagePosition: PropTypes.oneOf(["left", "right"]),
  }),
};

export default ParagraphTeaserImage;
