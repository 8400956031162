import React, { Component } from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "../../../app-actions";
import {self} from "../../../config";
import FieldTime from "../../date-time/field-time";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

class TeaserNews extends Component {
  static defaultProps = { pagerFullPage: false };

  render() {
    return (
        <article className="node node-teaser teaser-news">
          <EditButton
            adminApp={this.props.adminApp}
            entityId={this.props.item.entityId}
            destinationRoute={this.props.location.pathname}
          />
          <Link
            onClick={() =>
              this.props.dispatch(
                pagerFullPageContextAction(this.props.pagerFullPage)
              )
            }
            className="flex-wrap"
            to={this.props.item.path.alias}
          >
            <div className="content-wrapper">
              <h4>{this.props.item.title}</h4>
              <div className={"meta-info"}>
                <FieldTime
                  timestamp={true}
                  date={this.props.item.publishedAt.value}
                  format={"DD.MM.YYYY"}
                />
              </div>
              {this.props.item.fieldTeasertext && (
                <div
                  className="teaser text"
                  dangerouslySetInnerHTML={{
                    __html: this.props.item.fieldTeasertext.processed,
                  }}
                />
              )}
            </div>
            <img className="link-arrow" alt="link arrow" src={`${self}/arrow-down-right.svg`}/>
          </Link>
        </article>
    );
  }
}

export const teaserNewsPropTypes = PropTypes.shape({
  entityId: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  publishedAt: PropTypes.shape({
    value: PropTypes.string,
    publishedAtOrNow: PropTypes.string,
  }),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldTeasertext: PropTypes.shape({
    processed: PropTypes.string,
  }),
  fieldTeaserbild: PropTypes.shape({
    entity: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
});

TeaserNews.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserNewsPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserNews));
