import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import { Link } from "react-router-dom";

import menuQuery from "./menu-query.graphql";
import {self} from "../config";
import {connect} from "react-redux";

const mapStateToProps = (reduxStore) => ({
  microsite: reduxStore.appStore.microsite,
});
/**
 * Close on menu link click.
 */
class SimpleNavigation extends Component {
  state = {
    menuOpen: false,
    openTree: false,
    showMore: false,
  };

  toggleFullScreenMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen }, () => {
      if (this.state.menuOpen) {
        document.body.classList.add("fullscreen-menu-open");
      } else {
        document.body.classList.remove("fullscreen-menu-open");
      }
      if (window.location.hash && (window.location.hash.replace('#', ''))) {
        let anchorLink = document.getElementById(window.location.hash.replace('#', ''));

        if (anchorLink) {
          let scrollPosition = anchorLink.getBoundingClientRect().top + document.documentElement.scrollTop - 250;

          window.scrollTo({
            top: scrollPosition,
            behavior: 'smooth'
          });
        }
      }
    });
  };

  showMore = (index) => {
    this.setState({
      showMore: true
    })
  }

  hideMore = (index) => {
    this.setState({
      showMore: false
    })
  }

  render() {
    let menuContent = this.props.mainMenuQuery
    if (this.props.microsite === "ggb") {
       menuContent = this.props.ggbMenuQuery
    }
    let display = ""
    if (this.props.microsite === "ggb") {
      display = "d-none"
    }
      return (
        <>
          <nav className="col main-menu-desktop d-none d-lg-flex">
            <ul>
              {!this.props.microsite &&
                <li className={`apotheken-a ${this.state.showMore ? 'active' : ''}`}>
                  <button onClick={() => this.showMore()}>
                    <img src={`${self}/Apotheken-A.svg`}/>
                  </button>
                </li>
              }
              {!menuContent.loading && menuContent.menuByName &&
                menuContent.menuByName.links.map((item, index) => (
                  <li key={index}>
                    {item.url.routed ? (
                      <a href={item.url.path}>{item.label}</a>
                    ) : (
                      <Link to={item.url.path}>{item.label}</Link>
                    )}
                  </li>
                ))}
            </ul>
          </nav>
          {!this.props.microsite &&
            <div className={`more-info ${this.state.showMore ? 'active' : ''}`}>
              <div className="inner-wrapper">
                <img className="apotheken-a" alt={"Das Logo der Apotheken"} src={`${self}/Apotheken-A.svg`}/>
                <div
                  className="text"
                >
                  Die GEDISA – Gesellschaft für digitale Services der Apotheken mbH wurde 2021 von 16 Apothekerverbänden und -vereinen gegründet. Als kompetenter Dienstleister und zuverlässiger IT-Partner kümmern wir uns mit viel Leidenschaft und Engagement um die Entwicklung und den Betrieb von digitalen Anwendungen im Apothekensektor. Dabei entwickeln wir innovative und nachhaltige Produkte für unsere Vor-Ort-Apotheken, ihre Kundinnen und Kunden sowie die Landesapothekerverbände und -vereine.
                </div>
                <button
                  className="btn btn-primary"
                  onClick={() => this.hideMore()}
                >
                  <img src={`${self}/close.svg`}/>
                </button>
              </div>
            </div>
          }
          {!this.props.microsite &&
            <div className={`apotheken-a mobile ${this.state.showMore ? 'active' : ''}`}>
              <button onClick={() => this.showMore()}>
                <img src={`${self}/Apotheken-A.svg`}/>
              </button>
            </div>
          }
          <div
            id="toggle-fullscreen-menu"
            className={this.state.menuOpen ? "active" : ""}
            onClick={() => this.toggleFullScreenMenu()}
          >
            <div className="bar1" />
            <div className="bar2" />
            <div className="bar3" />
            <div className="bar4" />
          </div>

          <div
            id="fullscreen-menu"
            className={this.state.menuOpen ? "active" : ""}
          >
            <div className="nav-wrap">
              <div className="container">
                <div className="row">
                  <div className="col-16 navigation">
                    <nav className="main-menu-desktop mobile">
                      {this.props.microsite &&
                        <Link className="site-logo-button" to="/">
                          <img src={`${self}/GEDISA-logo-white.svg`} alt={"Gedisa Logo"}/>
                        </Link>
                      }
                      <ul>
                        {!menuContent.loading && menuContent.menuByName &&
                          menuContent.menuByName.links.map(
                            (item, index) => (
                              <li
                                onClick={() =>
                                  item.url.path !== ""
                                    ? this.toggleFullScreenMenu()
                                    : ""
                                }
                                className="row"
                                key={index}
                              >
                                <div className="col-16">
                                  <>
                                    {item.url.path !== "" ? (
                                      <Link to={item.url.path}>{item.label}</Link>
                                    ) : (
                                      <span
                                        onClick={() =>
                                          this.setState({ openTree: index })
                                        }
                                      >
                                      {item.label}
                                    </span>
                                    )}

                                    {item.links.length > 0 && (
                                      <ul
                                        className={
                                          this.state.openTree === index
                                            ? "active offset-2"
                                            : "offset-2"
                                        }
                                      >
                                        {item.links.map((linkItem, linkIndex) => (
                                          <li
                                            onClick={() =>
                                              linkItem.url.path !== ""
                                                ? this.toggleFullScreenMenu()
                                                : ""
                                            }
                                            key={linkIndex}
                                          >
                                            <Link to={linkItem.url.path}>
                                              {linkItem.label}
                                            </Link>
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                  </>
                                </div>
                              </li>
                            )
                          )}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
}

SimpleNavigation.propTypes = {
  mainMenuQuery: PropTypes.object,
  oftenClickedMenuQuery: PropTypes.object,
};

export default compose(
  connect(mapStateToProps),
  graphql(menuQuery, {
    options: {
      variables: { name: "gb-menu" },
    },
    name: "ggbMenuQuery",
  }),
  graphql(menuQuery, {
    options: {
      variables: { name: "main" },
    },
    name: "mainMenuQuery",
  }),
  graphql(menuQuery, {
    options: {
      variables: { name: "haeufig-geklickt" },
    },
    name: "oftenClickedMenuQuery",
  })
)(SimpleNavigation);
