import Image from "../../../../general-components/image/image";
import React from "react";

function ParagraphProjektTeaserGgb(props) {
  return (
    <>
      <div className="flex-wrap">
        {props.item.fieldTeaserbild && (
          <Image
            className={`teaser-img`}
            data={props.item.fieldTeaserbild.entity.fieldMediaImage}
          />
        )}
        {props.item.title && <h2 className={`headline`}>{props.item.title}</h2>}
        {props.item.fieldTeasertext && (
          <p
            className={`subline`}
            dangerouslySetInnerHTML={{
              __html: props.item.fieldTeasertext.value,
            }}
          />
        )}
      </div>
      {props.item.fieldButton && (
        <a href={props.item.fieldButton.url.path} className={`btn`}>
          Mehr
        </a>
      )}
    </>
  );
}
export default ParagraphProjektTeaserGgb;
