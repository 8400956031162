import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import nodeRevisionQuery from "./node-revision-query.graphql";
import ContentBase from "../general-components/content-base/content-base";
import LoadingIndicator from "../general-components/loading-indicator";

const mapStateToProps = () => ({});

class NodeRevision extends Component {
  state = {};

  render() {
    if (this.props.data.nodeRevisionById) {
      return (
        <article
          className={`node-${this.props.data.nodeRevisionById.entityBundle} node-${this.props.data.nodeRevisionById.entityId} node-full-page`}
        >
          <Helmet>
            <title>{`GEDISA | ${this.props.data.nodeRevisionById.entityLabel}`}</title>
          </Helmet>

          <ContentBase
            content={this.props.data.nodeRevisionById.fieldModules}
            nodeContent={this.props.data.nodeRevisionById}
          />
        </article>
      );
    }

    return <LoadingIndicator />;
  }
}

NodeRevision.propTypes = {
  data: PropTypes.object.isRequired,
};

export default graphql(nodeRevisionQuery, {
  options: (props) => ({
    variables: { revisionId: props.match.params.revisionId },
  }),
})(connect(mapStateToProps)(withRouter(NodeRevision)));
