import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Projekt from "../../../projekt/projekt";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "../../../app-actions";
import Image from "../../image/image";
import {self} from "../../../config";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
  microsite: reduxStore.appStore.microsite,

});

class TeaserProjekt extends Component {

  state = {
    isOpen: false,
    scrollOptions : {
      reserveScrollBarGap: true,
      allowTouchMove: el => {
        while (el && el !== document.body) {
          if (el.classList.contains('body-scroll-lock-ignore')) {
            return true;
          }

          el = el.parentElement;
        }
      }
    }
  };

  overlay = React.createRef();

  static defaultProps = { pagerFullPage: false };

  openPage = () => {
    this.setState({
      isOpen: true
    });
    disableBodyScroll(this.overlay.current, this.state.scrollOptions);
  }

  closePage = () => {
    this.setState({
      isOpen: false
    });
    enableBodyScroll(this.overlay.current, this.state.scrollOptions);
  }

  render() {
    if (this.props.microsite) {
      return (
        <>
          <article
            className="node node-teaser teaser-projekt"
          >
            <div className="flex-wrap">
              teaser-project
            </div>

          </article>
        </>
      );
    }else{
      return (
        <>
          <article
            className="node node-teaser teaser-projekt"
            onClick={() => this.openPage()}
          >
            <div className="flex-wrap">
              <div className="image-wrapper">
                <Image
                  className="teaser-bild"
                  data={this.props.item.fieldTeaserbild.entity.fieldMediaImage}
                />
              </div>
              <div className="text-wrap">
                <EditButton
                  adminApp={this.props.adminApp}
                  entityId={this.props.item.entityId}
                  destinationRoute={this.props.location.pathname}
                />

                <h3>{this.props.item.title}</h3>
                {this.props.item.fieldTeasertext &&
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html: this.props.item.fieldTeasertext.processed,
                    }}
                  />
                }

                {/*
                  <div className="tags">
                  {this.props.item.fieldSchlagwort.length >= 1 &&
                    this.props.item.fieldSchlagwort.map((item, index) => (
                      <React.Fragment key={index}>
                        {item.entity !== null && (
                          <span className="tag">{item.entity.name}</span>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                */}
              </div>
              {this.props.item.fieldTeasericons &&
                <Image
                  className="teaser-icon"
                  data={this.props.item.fieldTeasericons.entity.fieldMediaImage}
                />
              }
            </div>

          </article>
          <div className={`page-overlay ${this.state.isOpen ? 'active' : ''}`} ref={this.overlay}>
            <div className="overlay-wrapper">
              <div className="button-wrapper">
                <Projekt customPath={this.props.item.path.alias}/>
                <button className="close-button" onClick={() => this.closePage()}>
                  <img src={`${self}/close.svg`}/>
                </button>
              </div>
            </div>
          </div>
        </>
      );
    }

  }
}

export const teaserProjectPropTypes = PropTypes.shape({
  entityId: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldTeaserbild: PropTypes.shape({
    entity: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
});

TeaserProjekt.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserProjectPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserProjekt));
