import React, { Component } from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { FormattedMessage, injectIntl } from "react-intl";

import { trunc } from "../../../lib/shorten-text";

import EditButton from "../../../backend/edit-button";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

/**
 * @todo use "FieldTime"
 */
class TeaserEvent extends Component {
  static defaultProps = { pagerFullPage: false };

  render() {
    return (
      <article
        className={`node node-${this.props.item.entityId} node-teaser node-teaser-default teaser-veranstaltung col-16`}
      >
        <EditButton
          adminApp={this.props.adminApp}
          entityId={this.props.item.entityId}
          destinationRoute={this.props.location.pathname}
        />
        <div className="row">
          <div className="col-16 col-lg-3">
            {this.props.item.fieldBild !== null && (
              <Image
                data={this.props.item.fieldBild.entity.fieldMediaImage}
                nodeTitle={this.props.item.title}
              />
            )}
          </div>
          <div className="col-16 col-lg-3 d-none d-lg-block">
            <div className="meta-date top-line">
              <span>
                {moment
                  .utc(this.props.item.fieldDatum.value)
                  .local()
                  .format("dddd")}
              </span>
              {moment
                .utc(this.props.item.fieldDatum.value)
                .local()
                .format("HH:mm") !== "00:00" && (
                <span>
                  {moment
                    .utc(this.props.item.fieldDatum.value)
                    .local()
                    .format("HH:mm")}{" "}
                  Uhr
                </span>
              )}
            </div>
            <div className="date">
              <span>
                {moment
                  .utc(this.props.item.fieldDatum.value)
                  .local()
                  .format("DD.MM.YYYY")}
              </span>
            </div>
            <div className="location">{this.props.item.fieldOrt}</div>
            {this.props.item.fieldDatum.endValue &&
              !this.props.item.fieldDauerAusblenden && (
                <div className="duration">
                  Dauer{" "}
                  {Math.round(
                    moment
                      .duration(
                        moment
                          .utc(this.props.item.fieldDatum.endValue)
                          .local()
                          .diff(
                            moment.utc(this.props.item.fieldDatum.value).local()
                          )
                      )
                      .asHours()
                  )}{" "}
                  Std.
                </div>
              )}
          </div>
          <div className="col-16 d-block d-lg-none mobile-header">
            <div className="row">
              <div className="col-8">
                <span>
                  {moment
                    .utc(this.props.item.fieldDatum.value)
                    .local()
                    .format("dd, DD.MM.YYYY")}
                </span>
                {this.props.item.fieldSchlagwort.length > 0 &&
                  this.props.item.fieldSchlagwort[0].entity && (
                    <span>
                      {this.props.item.fieldSchlagwort[0].entity.name}
                    </span>
                  )}
              </div>
              <div className="col-8 d-flex align-items-end">
                <span>{this.props.item.fieldOrt}</span>
                {moment
                  .utc(this.props.item.fieldDatum.value)
                  .local()
                  .format("HH:mm") !== "00:00" && (
                  <span>
                    {moment
                      .utc(this.props.item.fieldDatum.value)
                      .local()
                      .format("HH:mm")}{" "}
                    Uhr
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="col-16 col-lg-6">
            {this.props.item.fieldSchlagwort.length > 0 &&
              this.props.item.fieldSchlagwort[0].entity && (
                <span className="top-line d-none d-lg-block">
                  {this.props.item.fieldSchlagwort[0].entity.name}
                </span>
              )}
            <h3>{this.props.item.title}</h3>
            <div
              className="text teaser-text d-none d-lg-block"
              dangerouslySetInnerHTML={{
                __html: `${trunc(
                  this.props.item.fieldText.processed,
                  150,
                  true
                )} <a href="{this.props.item.entityUrl ? this.props.item.entityUrl.path : this.props.item.path.alias}">${this.props.intl.formatMessage(
                  { id: "read_more" }
                )}</a>`,
              }}
            />
          </div>
          <div className="d-none col-16 col-lg-4 justify-content-lg-end d-lg-flex align-items-lg-baseline">
            <a
              href="src/js/general-components/teaser-base#"
              className="btn btn-primary"
            >
              <FormattedMessage id="event.remember" />
            </a>
            {this.props.item.fieldTicketlink && (
              <a
                href={this.props.item.fieldTicketlink.url.path}
                className="btn btn-primary"
              >
                <FormattedMessage id="event.ticket" />
              </a>
            )}
          </div>
        </div>
      </article>
    );
  }
}

export const teaserEventPropTypes = PropTypes.shape({
  entityId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  entityUrl: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldOrt: PropTypes.string,
  fieldDauerAusblenden: PropTypes.bool,
  fieldText: PropTypes.shape({
    processed: PropTypes.string.isRequired,
  }),
  fieldBild: PropTypes.shape({
    entity: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
  fieldDatum: PropTypes.shape({
    value: PropTypes.string,
    endValue: PropTypes.string,
  }),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldTicketlink: PropTypes.shape({
    url: PropTypes.shape({
      path: PropTypes.string,
    }),
  }),
});

TeaserEvent.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserEventPropTypes,
  location: PropTypes.object.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(injectIntl(TeaserEvent)));
