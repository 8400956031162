import React, { Component } from "react";

import SimpleNavigation from "./simple-navigation";
import LogoButton from "../general-components/logo-button";
import {connect} from "react-redux";

const mapStateToProps = (reduxStore) => ({
  microsite: reduxStore.appStore.microsite,
});
class SimpleHeader extends Component {

  state = {
    alternativeHeader: false,
    visibility: true,
    scrollPos: 0
  }

  handleScroll = () => {
    if (!this.state.alternativeHeader) {
      this.setState({
        alternativeHeader: true
      });
    }
    if (scrollY < this.state.scrollPos || scrollY < (window.innerHeight)/2) {
      this.setState({
        visibility: true
      });
    } else {
      this.setState({
        visibility: false
      });
    }
    this.setState({
      scrollPos : scrollY
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
  }

  render() {
    return (
      <header id="site-header" className= {[this.state.visibility ? 'show' : 'hide',this.state.alternativeHeader ? 'alternative' : ''].join(' ')}>
        <div className="fixed-wrapper">
          <div className="container full-size">
            <div className="row">
              <div className="col logo-col">
                <LogoButton />
              </div>
              <SimpleNavigation location={this.props.location} />
              {this.props.microsite &&
                <div className="col logo-col spacer"/>
              }
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default connect(mapStateToProps)(SimpleHeader);
